























































































































import {Component, Vue} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import {excuteDownloadExport2Excel} from "@/common/xlsx";
import MemberFeeAPI from "@/common/api/memberFee";
export const pageApiList = getPageAPIs(MemberFeeAPI);
@Component({})
export default class UserList extends Vue {
  private buyAddressApi: any = new MemberFeeAPI();

  private form: any = {
    phone: "",
    order_no: "",
    time: [], // 时间选择
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.buyAddressApi.member_fee(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // channel渠道字段名 文案对应关系
  private merchantExcelList2ch: any = {
    phone: "手机号",
    user_name: "用户名",
    order_no: "订单编号",
    type: "会员等级",
    shop_name: "店铺名称",
    pool_fee: "台球费用折扣前",
    pool_discount_fee: "台球费用折扣后",
    coach_fee: "助教费用折扣前",
    coach_discount_fee: "助教费用折扣后",
    goods_infos: "商品信息",
    before_total_money: "消费总金额折扣前",
    after_total_money: "消费总金额折扣后",
    before_account_money: "会员余额变动前",
    after_account_money: "会员余额变动后",
    use_time: "消费时间",
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "phone",
    "user_name",
    "order_no",
    "shop_name",
    "pool_fee",
    "pool_discount_fee",
    "coach_fee",
    "coach_discount_fee",
    "goods_infos",
    "before_total_money",
    "after_total_money",
    "before_account_money",
    "after_account_money",
    "use_time",
  ];

  // 获取excelJSON数据
  private getExcelJSONDataByList(list: any[], sortKey: string[], map2ch: any) {
    const newList = list.map((eachItem) => {
      const newItem: any = {};

      for (const key of sortKey) {
        const val = eachItem[key];

        const chKey = map2ch[key];
        if (val) {
          newItem[chKey] = val;
        } else {
          newItem[chKey] = "";
        }
      }

      return newItem;
    });

    return newList;
  }

  // 点击导出excel 表格
  private handleExportExcelChannelList() {
    const form = this.form;
    const {time, channelId} = form;

    const params: any = {
      channelId,
    };

    if (Array.isArray(time) && time.length === 2) {
      params.startDate = Util.dateTimeUtilnohms(this.form.time[0]);
      params.endDate = Util.dateTimeUtilnohms(this.form.time[1]);
    } else {
      this.$message.error("请选择时段");

      return;
    }

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.buyAddressApi.member_fee(
        {...this.form, is_export: 1},
        (res: any) => {
          const start_date: string = Util.dateUtil(this.form.time[0]);
          const end_date: string = Util.dateUtil(this.form.time[1]);
          const filename = `会员管理 ${start_date}到${end_date}.xlsx`;
          // const { list: originList } = res.data || {};
          for (let x of res.data.list) {
            if (x.goods_info.length > 0) {
              let goods_infos = "";
              for (let i of x.goods_info) {
                goods_infos += i.name + "×" + i.num + " " + "￥" + i.price + ";";
              }
              x.goods_infos = goods_infos;
            }
          }

          const originList = res.data.list || [];

          // const {
          //   list: originList
          // } = data || {}

          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                // const { orderNum } = item || {};
                const newItem = {
                  ...(item || {}),
                };

                for (let key in item) {
                  if (typeof item[key] == "number") {
                    newItem[key] = item[key].toString();
                  }
                }

                // newItem.orderNum =
                //   typeof orderNum == "number"
                //     ? orderNum.toString()
                //     : orderNum || "";

                return newItem;
              })
              : [];

          if (newList.length > 0) {
            const merchantExcelSortList = this.merchantExcelSortList;

            const merchantExcelList2ch = this.merchantExcelList2ch;

            const excelJSONData = this.getExcelJSONDataByList(
                newList,
                merchantExcelSortList,
                merchantExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }

          // const testData = [
          //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
          //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
          //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
          //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
          //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
          // ]

          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }

  /** end 导出excel */
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
