import {BasicsClass} from "@/common/BasicsClass";

export default class MemberFeeAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/member_fee/index',
            name: 'list',
            label: '列表'
        }],
    ])

    public member_fee(
        params: any,
        successCb: Function,
        failCb: Function,
        showLoading: boolean = false
    ) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
